import React, {useRef, useState} from 'react';
import './BodyBlock.css'
import YouTube from 'react-youtube';
import {Empty, Modal, Tooltip} from 'antd';
import {lanGs} from "../utils/langData";
import {ReactComponent as PDF} from "../assets/icon/pdf.svg"
import {ReactComponent as Vid} from "../assets/icon/video.svg"
import TableBlock from "./TableBlock";
import SmallTable from "./SmallTable";
import Bi from "../assets/icon/bi.png";

const BodyBlock = ({lang, distChoice, meetings, meetingId, setMeetingId, metListPP, metListQ}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenBI, setIsModalOpenBI] = useState(false);
    const [videoLink, setVideoLink] = useState(null);
    const [isPaused, setIsPaused] = useState(false);

    const youtubePlayerRef = useRef(null);
    const stopVideo = () => {
        if (youtubePlayerRef.current) {
            youtubePlayerRef.current.internalPlayer.stopVideo();
        }
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleOkBI = () => {
        setIsModalOpenBI(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        stopVideo()
        setIsPaused(!isPaused);
        setVideoLink(null)
    };
    const handleCancelBI = () => {
        setIsModalOpenBI(false);
    };
    const onReady = event => {
        // Запуск видео
        event.target.playVideo();
    };
    return (
        <div className={'BodyBlock_wrapper'}>

            <div className={'BodyBlock metList_wrap'}>
                <span className={'metList_title_block'}>{lanGs['Список встреч акима с жителями'][lang]}</span>
                <span className={'metList_title_dist'}>{distChoice[lang]}</span>

                <div className={'metList_items'}>
                    {meetings ?
                        meetings.map(i => {
                        return <div className={`metList_item_wrap ${meetingId?.id === i.id ? 'active' : ''}`} onClick={() => setMeetingId(i)} key={i.id + Math.random()}>

                            {distChoice?.id === 0 && <div className={'hide_dist'}>{i?.districts?.length  > 0  && i?.districts?.map(it => {
                                return <span>{it[lang]}{i?.districts?.length > 1 && ' |'}</span>
                            })}</div>}
                            <div className={'metList_item_date'}><span>{lanGs['Дата встречи'][lang]} </span> <span>{i.date}</span></div>
                            <div className={'metList_item_plan'} style={{gridRow: distChoice?.id === 0 ? '2/span 2' : '1/span 2'}}>
                                <Tooltip title={lanGs['Количество исполненных поручений'][lang]}>
                                    <span style={{color: '#008767'}}>{i?.fact ? i.fact +'/' : ''}</span>
                                </Tooltip>
                                <Tooltip title={lanGs['Общее количество поручений акима по итогам встречи'][lang]}>
                                <span>{i?.plan ? i.plan : 0}</span>
                                </Tooltip>
                            </div>
                            <div className={'metList_item_btn'}>
                                <Tooltip title={lanGs['Протокол встречи акима с населением, для просмотра'][lang]}>
                                    {i.docUrl ?<a href={`${i.docUrl && i.docUrl}`} target={'_blank'} rel="noreferrer" className={'metList_item_pdf'}>
                                        <PDF/> <span>{lanGs['Протокол'][lang]}</span>
                                    </a>:
                                        <div className={'metList_item_pdf'} style={{cursor: "initial"}}><PDF/> <span>{lanGs['Протокол'][lang]}</span></div>
                            }
                                </Tooltip>
                                <Tooltip title={lanGs['Видеозапись встречи акима с населением, для просмотра'][lang]}>
                                <div className={'metList_item_video'}
                                     style={{cursor: i?.videoUrl ? 'inherit':"initial"}}
                                     onClick={() => {
                                         i?.videoUrl &&  setIsModalOpen(true)
                                         i?.videoUrl && setVideoLink(i?.videoUrl?.split('=')[1]?.split('&')[0])
                                     }}
                                >
                                    <Vid /> <span>{lanGs['Смотреть'][lang]}</span></div>

                                </Tooltip>
                            </div>

                        </div>

                    }): <span className={'NO_DATA'}><Empty /></span>}
                </div>

            </div>
            <div className={'BodyBlock metList_PP_wrap'}>
                <div className={'metList_PP_block'}>
                    { meetingId && <span className={'metList_title_block'}>
                           {lanGs['Поручения по итогам встречи акима с жителями г. Алматы'][lang]}
                        {/*{meetingId?.district &&  '/ ' + meetingId?.district[lang]}*/}

                        {(meetingId?.districts?.length > 0 && distChoice?.id === 0) ?
                            meetingId.districts.map(it => {
                                return '/ ' + it[lang] + ' '
                            }) :
                            '/ ' + meetingId?.districts[0][lang] + ' '
                        }


                        {meetingId?.date ? meetingId.date + 'г.' : ''}
                        </span>}
                    <div className={'metList_PP_table'}>
                        <TableBlock lang={lang} data={metListPP}/>
                    </div>
                </div>
            </div>
            {/*<div className={'BodyBlock metList_Bi'}>
                <div className={'metList_Bi_logo'} onClick={() => {
                    setIsModalOpenBI(true)}}>
                    <img src={Bi} alt={'Bi'}/>
                    <div className={'metList_Bi_logo_text'}>
                        <span>{lanGs['Аналитика поручений'][lang]}</span>
                        <span>{lanGs['Просмотреть аналитику'][lang]}</span>
                    </div>
                </div>
            </div>*/}
            <div className={'BodyBlock metList_appeals_wrap'}>
                <div className={'metList_PP_block'}>
                    <span className={'metList_title_block'}>{lanGs['Обращения, по которым даны разъяснения в ходе встречи'][lang]}.</span>
                    <div className={'metList_PP_tableS'}>
                        <SmallTable lang={lang} data={metListQ}/>
                    </div>
                </div>
            </div>
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} header={false} footer={false} centered className={'BodyBlock_modal'} width={650}>
                {videoLink ? <YouTube videoId={videoLink} ref={youtubePlayerRef} onReady={onReady}/>: <div className = {' NO_VIDEO'}>
                    <span className={'NO_VIDEO_text'}>{lanGs['Видео временно не доступно'][lang]}</span>
                </div>}
            </Modal>
            <Modal open={isModalOpenBI} onOk={handleOkBI} onCancel={handleCancelBI} header={false} footer={false} centered className={'BodyBlock_modal_BI'} width={650}>
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe width="80vw" height="80vh" src={'https://power.smartalmaty.kz:443/powerbi/?id=cb61308b-7504-4a4f-b088-25cface904c7&formatLocale=ru-RU'}/>
            </Modal>
        </div>
    );
};

export default BodyBlock;
